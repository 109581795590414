module ute.common.typescript.api.log {
  'use strict';

  export enum LogLevel {
    OFF,
    FATAL,
    ERROR,
    WARN,
    INFO,
    DEBUG,
    TRACE,
    ALL
  }
}

