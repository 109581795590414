module ute.common.typescript.api.types {
  'use strict';

  interface IEmailConstructor extends StringConstructor {
  }
  //declare var Email: IEmailConstructor;

  export interface IMultiLangObject<T> {
    EN: T;
    FR: T;
  }

  export type SupportedLanguagesUpper = 'EN' | 'FR';

  export type BillType = 'online' | 'paper';

  export interface ICallback<T> {
    (data: T): ng.IPromise<T>;
  }

  export interface ICallbackHandler<T> {
    callback: ICallback<T>;
    id: string;
  }

  export interface ISubPubGeneric<T> {
    registerOnDataChangeHandler: (callback: ICallback<T>, id: string) => boolean;
    unregisterOnDataChangeHandler: (id: string) => boolean;
  }

  export interface ITranslate {
    translateId: string;
    translateValues?: _.Dictionary<string>;
  }

  export interface IResponseStatus {
    status: {
      code: number;
      codeName: string;
      requestId: string;
      // description: string;
    };
  }

  export interface IResponse<T> extends IResponseStatus {
    content?: T;
  }

  export interface ICodeEntry {
    frontendCode?: number;
    backendCode?: number;
    errorTitleMessageKey?: string;
    errorSubTitleMessageKey?: string;
    frontendMessageKey?: string;
    frontendMessageP2Key?: string;
    windowTemplateUrl?: string;
    templateUrl?: string;
    controller?: string;
    modalSize?: string;
    modalIcon?: string;
    boldChat?: IBoldChat;
    // isModal?: boolean;
  }

  export interface IBoldChat {
    cbid: string;
    dynamicDivId: string;
    chatButtonClass: string;
  }
}
