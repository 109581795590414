module ute.common.typescript.api {
  'use strict';

  /**
   * Main configuration interface
   */
  export interface IServiceProvider<T> extends ng.IServiceProvider {
    configure(config: T): boolean;
    //getConfig(): T;
  }

  /**
   * Parameterless function returning an instance of T.
   * Recommended to use in Class Factory implementations.
   */
  export interface IFunction<T> {
    (): T;
  }
}
