module ute.common.typescript.api.mixin {
  'use strict';

  export class MixinHelper {
    /**
     * apply Mixins pattern to the derivedCtor class
     * example:
     * class SmartObject implements Disposable, Activatable {
     *   ...
     * }
     * applyMixins(SmartObject, [Disposable, Activatable]);
     *
     * @param derivedCtor
     * @param baseCtors
     */
    public static applyMixins: (derivedCtor: any, baseCtors: any[]) => void = (derivedCtor: any, baseCtors: any[]) => {
      baseCtors.forEach(baseCtor => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
          derivedCtor.prototype[name] = baseCtor.prototype[name];
        });
      });
    };
  }
}
